@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
@import "src/app/components/Header/index.css";
@import "src/app/components/Footer/index.css";
@import "src/app/components/Button/index.css";
@import "src/app/components/HeroSection/variant-1/index.css";
@import "src/app/components/HeroSection/variant-2/index.css";
@import "src/app/components/FeatureList/variant-1/index.css";
@import "src/app/components/FeatureList/variant-2/index.css";
@import "src/app/components/Feature/variant-1/index.css";
@import "src/app/components/Feature/variant-2/index.css";
@import "src/app/components/Form/index.css";
@import "src/app/components/ForgotPassword/index.css";
@import "src/app/views/ForgotPassword/ForgotPassword.css";
@import "src/app/views/Login/Login.css";
@import "src/app/views/Verify/Verify.css";

a:hover {
  color: black !important;
}

.App {
  min-height: 100vh !important;
}

.error-txt {
  color: red;
  text-align: center;
  margin-top: -1.2rem;
  padding-bottom: 0.5rem;
}

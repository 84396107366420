.hero-section-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 8rem;
  font-family: 'fractul-regular';
  padding-bottom: 3rem;
  /* margin-top: 2rem; */
  /* padding-top: 1rem; */
}

.hero-title-1 {
  font-size: 4rem;
  padding-top: 4rem;
}

.hero-text-1 {
  font-size: 100%;
  font-family: 'fractul-regular';
  width: 60%;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.hero-image {
  padding-top: 2rem;
  width: 40%;
}

@media screen and (max-width: 1550px) {
  .hero-title-1 {
    font-size: 3rem;
  }
  
  .hero-text-1 {
    font-size: 100%;
    width: 70%;
  }
}

@media screen and (max-width: 1371px) {
  .hero-title-1 {
    font-size: 2.75rem;
  }
  
  .hero-text-1 {
    font-size: 80%;
    width: 65% !important;
  }
}

@media screen and (max-width: 1278px) {
  .hero-title-1 {
    font-size: 2.3rem;
  }
  
  .hero-text-1 {
    font-size: 70%;
    width: 65% !important;
  }
}

@media screen and (max-width: 992px) {
  .hero-image {
    display: none;
  }
  .hero-section-1 {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-family: 'fractul-regular';
  }

  .align-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .hero-title-1 {
    font-size: 3.2rem;
  }
  
  .hero-text-1 {
    font-size: 120%;
    font-family: 'fractul-regular';
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .hero-image {
    display: none;
  }
  .hero-section-1 {
    margin-top: 2rem;
    font-family: 'fractul-regular';
    padding-top: 2rem;
    padding-bottom: 5rem;
  }

  .hero-title-1 {
    /* text-align: ; */
    font-family: 'fractul-medium';
    font-size: 2.2rem;
    width: 100% !important;
  }
  
  .hero-text-1 {
    font-size: 90%;
    width: 100% !important;
    font-family: 'fractul-regular';
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
}

@media screen and (max-width: 516px) {
  .hero-image {
    display: none;
  }
  .hero-section-1 {
    margin-top: 2rem;
    font-family: 'fractul-regular';
    padding-top: 2rem;
    padding-bottom: 3.2rem;
  }

  .hero-title-1 {
    /* text-align: ; */
    font-family: 'fractul-medium';
    font-size: 1.8rem;
    width: 100% !important;
  }
  
  .hero-text-1 {
    font-size: 80%;
    line-height: 1rem;
    width: 100% !important;
    font-family: 'fractul-regular';
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 424px) {
  .hero-title-1 {
    /* text-align: ; */
    font-family: 'fractul-medium';
    font-size: 1.6rem;
    width: 100% !important;
  }
  .hero-text-1 {
    font-size: 80%;
    line-height: 1rem;
    width: 100% !important;
    font-family: 'fractul-regular';
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

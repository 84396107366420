.footer {
  position: relative;
  /* bottom: 0;
  right: 0; */
  width: 100%;
  min-height: 20vh;
  /* background-color: rgb(35, 78, 252) !important; */
}

h4 {
  padding-top: 1rem !important;
  text-transform: uppercase;
  font-size: 1rem !important;
}

ul {
  list-style: none;
  padding-left: 0 !important;
  padding-top: 1rem;
}

ul li {
  padding: 2px 0;
}

ul li a {
  color: rgb(77, 76, 76);
  text-decoration: none;
  font-size: 0.9rem;
}

ul li a:hover {
  color: #234efc !important;
}

.icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(51, 51, 51, 0.3);
  color: #fff;
  padding: 2px 10px;

  /* width: 250px; */
  max-width: 250px;
}

.icons p {
  /* border: 1px solid #333; */
  margin-left: 10px;
  min-height: 100%;
  color: #fff;
  line-height: 1.2rem;
}

.bigger-text {
  font-size: 1.4rem;
}

.icon-item {
  font-size: 2rem;
}

.tuned {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tuned > * {
  padding: 0 5px;
}

.tuned ul li a:hover {
  color: #000 !important;
}

.select-language {
  border-radius: 10px;
  background-color: rgba(51, 51, 51, 0.3);
  color: #fff;
  padding: 5px;
  border: none;
  width: 150px;
}

.select-language:hover {
  cursor: pointer;
}

.select-language:focus {
  outline: none;
  outline-color: transparent;
}

@media screen and (max-width: 767px) {
  .supported-devices {
    display: flex;
    flex-direction: column;

    align-items: center;
  }

  .tuned {
    display: flex;
    justify-content: space-between;
  }
  .icons {
    width: 250px;
  }

  .copyright {
    text-align: center;
    padding-top: 1rem;
  }

  .logo-footer {
    text-align: center;
  }
}

@media screen and (max-width: 1143px) {
  .bigger-text {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1040px) {
  .supported-devices p {
    font-size: 0.8rem;
  }
  .bigger-text {
    font-size: 1.1rem;
  }
}

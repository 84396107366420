.page-container {
  background: url("../../../assets/svg/Register/register_back.svg") center
    center no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-form {
  position: relative;
  z-index: 1;
}

.page-heading {
  position: relative;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.page-heading img {
  position: absolute;
  z-index: 100;
}

.human {
  width: 30%;
  top: 32%;
  left: 70%;
}

.plant {
  width: 10%;
  top: 84%;
  right: 74%;
}

.page-heading-title {
  font-family: "fractul-regular";
  font-size: 400%;
  margin-bottom: -10px;
}

.page-heading-description {
  font-family: "fractul-regular";
  font-size: 100%;
  width: 60%;
  text-align: center;
}

@media screen and (max-width: 810px) {
  .page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
  }

  .page-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  .page-heading-title {
    text-align: center;
    font-family: "fractul-medium";
    font-size: 250%;
    margin-bottom: -10px;
  }

  .page-heading-description {
    font-family: "fractul-regular";
    font-size: 100%;
    width: 70%;
    text-align: center;
  }
}

.background {
  position: absolute;
  z-index: -100;
  width: 55%;
}

.svg-left {
  position: absolute;
  z-index: -9;
  width: 5%;
}

.svg-right {
  position: absolute;
  z-index: 200;
  width: 20%;
}

@media screen and (max-width: 991px) {
  .human {
    display: none;
  }
  .plant {
    display: none;
  }
}

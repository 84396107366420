.form-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: fractul-regular;
  /* height: 50%;
  width: 22%; */
  color: #333;
  /* min-height: 100; */
}

.form-element > * {
  margin: 10px 0;
  z-index: 9999 !important;
}

.form-element button,
.form-element input {
  width: 100% !important;
}

.form-element input {
  border: none;
  padding: 10px 5px;
}

.form-element input:focus {
  outline-color: #00b609;
}

.container {
  max-width: 87% !important;
}

.feature-list {
  background-color: #234efb;
  color: #fff;
  padding: 50px 0;
  min-height: 100vh;
}

.verify {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  height: 100%;
  min-height: 30vh;
  font-family: "fractul-regular";
  flex-direction: column;
}

.verify p {
  width: 60%;
  text-align: center;
  margin-top: 3%;
  color: #333;
}

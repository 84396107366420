.woman {
  width: 80%;
  bottom: -5%;
  left: -70%;
}

.cactus-login {
  width: 20%;
  bottom: -5%;
  right: -10%;
}

.trashcan {
  width: 20%;
  bottom: -5%;
}

.page-heading-title.login {
  margin-bottom: 2rem;
  text-align: center;
}

.page-heading.login {
  min-height: 60vh !important;
}

.page-container.login {
  background: url("../../../assets/svg/login_back.svg") center center no-repeat;
}

@media screen and (max-width: 991px) {
  .woman {
    display: none;
  }
  .cactus-login {
    display: none;
  }
  .trashcan {
    display: none;
  }
}
@media screen and (max-height: 561px) {
  .woman {
    width: 50%;
    left: -40%;
  }
  .cactus-login {
  }
  .trashcan {
    display: none;
  }
  .page-heading-title.login {
    margin-bottom: 5rem;
    /* text-align: center; */
  }
}

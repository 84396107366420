.button {
  background-color: #00b609;
  color: #fff;
  border: none;
  text-align: center;
  margin: auto;
  font-family: 'fractul-regular';
  min-width: 11rem;
  font-size: 1.1rem;
  padding: 11px 0;
}

.button-arrow {
  background-color: #00b609;
  color: #fff;
  font-size: 1rem;
  min-width: 12rem;
  border: none;
  text-align: center;
  margin: auto;
  padding: 12px 0;
  font-family: 'fractul-regular';
}

::placeholder {
  opacity: 60%;
}

:-ms-input-placeholder {
  opacity: 60%;
}

:-ms-input-placeholder {
  opacity: 60%;
}

.form {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  flex-shrink: 0 !important;
  background-color: rgb(179, 202, 254);
  padding: 25px 60px;
}

.form-title {
  font-family: "fractul-regular";
  font-size: 200%;
  margin-bottom: 25px;
}

.form-link {
  font-family: "fractul-medium";
  color: black;
}

.form > button {
  min-width: 300px !important;
}

.form-redirect {
  font-family: "fractul-regular";
  margin-bottom: 12px;
  cursor: pointer;
}

.form-input {
  font-family: "fractul-regular";
  min-width: 300px !important;
  padding: 15px 15px;
  outline: none;
  border: none;
  margin-bottom: 2.2rem;
}

.error-txt.register {
  min-height: 2rem !important;
  height: 2rem;
  margin-top: -2rem;
  font-size: 0.8rem;
  padding-bottom: 0.5rem;
}

@media screen and (max-width: 555px) {
  .form {
    min-width: 200px;
    padding: 15px 35px;
  }

  .form > button {
    min-width: 200px !important;
  }

  .form-redirect {
    font-family: "fractul-regular";
    margin-bottom: 12px;
  }

  .form-input {
    min-width: 200px !important;
    padding: 10px 10px;
  }
}

.grid-box {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  color: #fff;
  padding: 3rem 8.75rem;
  padding-bottom: 3rem;
}

.features-wrapper {
  background-color: #234efb;
  padding: 1rem 0;
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.background-images {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 0;
  top: 60%;
  /* margin-left: 24rem;
  margin-bottom: 5rem; */
}
.image-2 {
  width: 25%;
  /* max-width: 1200px; */
  position: absolute;
  /* top: 10%; */
  bottom: 10%;
  right: 19%;
  /* padding-left: 10rem; */
  /* padding-bottom: 10rem; */
  /* height: 100px; */
}
.image-1 {
  width: 25%;
  position: absolute;
  left: 19%;
  bottom: 0%;
  /* padding-left: 16rem; */
  /* padding-bottom: 16rem; */
}

@media screen and (max-width: 1600px) {
  .image-1 {
    bottom: 10%;
  }

  .image-2 {
    bottom: 15%;
  }
}
@media screen and (max-width: 1300px) {
  .image-1 {
    bottom: 20%;
  }

  .image-2 {
    bottom: 25%;
  }
}
@media screen and (max-width: 1100px) {
  .image-1 {
    bottom: 30%;
  }

  .image-2 {
    bottom: 35%;
  }
}

/* @media screen and (max-width: 1694px) {
  .grid-box {
    padding: 3rem 5rem;
  }
  .image-1 {
    width: 30%;
  }
  .image-2 {
    width: 30%;
  }
}

@media screen and (max-width: 1286px) {
  .grid-box {
    padding: 3rem 3rem;
  }
}

@media screen and (max-width: 1391px) {
  .background-images {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-1 {
    width: 60%;
  }
  .image-2 {
    width: 60%;
  }
} */
@media screen and (max-width: 992px) {
  .grid-box {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    color: #fff;
    padding: 3rem 1rem;
  }
  .background-images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: absolute;
    z-index: 0;
    margin-left: 10rem;
    margin-top: 10rem;
  }
  .image-1 {
    display: none;
  }
  .image-2 {
    display: none;
  }
}
/*
.features > div {
  margin: 5px 5px;
} */

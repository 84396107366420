.header-wrapper {
  background-color: #222222;
  display: flex;
  justify-content: center;
  font-size: 70%;
  height: max;
}

.header-container {
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  margin-bottom: -5px;
  width: 60%;
}

.header-key {
  font-family: 'fractul-light';
  color: rgb(216, 216, 216);
}

.header-value {
  font-family: 'fractul-regular';
}

.header-value-button {
  background-color: #FF4700;
  padding: 6px 14px;
  border-radius: 16px;
  font-family: 'fractul-regular';
  margin-right: 3px;
}

@media screen and (max-width: 1440px) {
  .header-container {
    width: 75%;
  }
}
@media screen and (max-width: 991px) {
  .header-container {
    width: 75%;
  }
  .p-hidden {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .header-container {
    width: 90%;
  }
}

@media screen and (max-width: 435px) {
  .header-wrapper {
    font-size: 70%;
  }
  .header-container {
    width: 90%;
  }
}

@font-face {
  font-family: "fractul-regular";
  src: url("./assets/fonts/Fractul-Regular.woff2");
}
@font-face {
  font-family: "fractul-bold";
  src: url("./assets/fonts/Fractul-Bold.woff2");
}
@font-face {
  font-family: "fractul-medium";
  src: url("./assets/fonts/Fractul-Medium.woff2");
}
@font-face {
  font-family: "fractul-light";
  src: url("./assets/fonts/Fractul-Light.woff2");
}
@import '@fortawesome/fontawesome-svg-core/styles.css'

.feature-1 {
  padding: 40px 0;
}

.feature-1 p {
  width: 61% !important;
}

.feature-title-1 {
  font-family: 'fractul-bold';
}

.feature-body-1 {
  font-family: 'fractul-light';
}

.feature-icon-1 {
  margin-bottom: .6rem;
  width: 40px;
}

.feature-icon-bolt {
  margin-bottom: .6rem;
  width: 30px;
}

@media screen and (max-width: 992px) {
  .feature-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;
  }

  .feature-1 p {
    width: 100% !important;
    text-align: center;
  }
  
  .feature-body-1 {
    max-width: 100%;
    text-align: justify;
  }

  .feature-1 .col-lg-4 {
    padding: 0;
  }
}

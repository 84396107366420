.hero-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.hero-title-2 {
  font-size: 4rem;
  font-family: 'fractul-bold';
}

.hero-text-2 {
  margin-top: 1rem;
  font-family: 'fractul-regular';
  font-size: 120%;
}

.font-blue-2 {
  color: #234efb;
}

@media screen and (max-width: 992px) {
  .hero-title-2 {
    font-size: 2.5rem;
  }
  .hero-text-2 {
    font-family: 'fractul-regular';
    font-size: 100%;
  }
}

@media screen and (max-width: 585px) {
  .hero-title-2 {
    font-size: 2.2rem;
  }
  .hero-text-2 {
    font-family: 'fractul-regular';
    font-size: 80%;
  }
}

@media screen and (max-width: 500px) {
  .hero-title-2 {
    font-size: 2rem;
  }
  .hero-text-2 {
    font-family: 'fractul-regular';
    font-size: 80%;
  }
}

@media screen and (max-width: 444px) {
  .hero-title-2 {
    font-size: 1.7rem;
  }
  .hero-text-2 {
    font-family: 'fractul-regular';
    font-size: 80%;
  }
}

@media screen and (max-width: 373px) {
  .hero-title-2 {
    font-size: 2.5rem;
  }
  .hero-text-2 {
    font-size: 70%;
  }
}

.component-wrapper {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 22rem;
  color: #212529;
  padding: 50px 0;
  min-height: 70vh;
}

@media screen and (max-width: 1650px) {
  .component-wrapper {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 4rem;
    color: #212529;
    padding: 50px 0;
    min-height: 80vh;
  }
  
}

@media screen and (max-width: 992px) {
  .component-wrapper {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10rem;
    color: #212529;
    padding: 50px 0;
    min-height: 100vh;
  }
}
.security-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
  min-height: 60vh;
  background-color: #234EFB;
  color: #fff;
}
.security-content {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}
.security-content-title {
  font-family: 'fractul-medium';
  font-size: 2em;
  /* padding-bottom: 0.2rem; */
}
.security-content-text-title {
  font-family: 'fractul-medium';
  font-size: 1.4em;
}
.security-content-icon {
  width: 300px;
  /* outline: 1px solid black; */
  padding-left: 6rem;
}
.security-content-text {
  width: 30%;
  position: relative;
  left: 7%;
  padding-bottom: 2rem;
  font-size: 1.2rem;
}
.security-content-icon-mobile {
  display: none;
}

@media screen and (max-width: 1300px) {
  .security-content-text {
    width: 35%;
    position: relative;
    left: 7%;
    padding-bottom: 2rem;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 992px) {
  .security-content-icon {
    display: none;
  }
  .security-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
  }
  .security-content-icon-mobile {
    display: block;
  }
  .security-content-text {
    text-align: center;
    min-width: 70%;
    max-width: 80%;
    position: relative;
    left: 0%;
    padding-bottom: 0rem;
    font-size: 1rem;
  }
  .security-content-title {
    font-family: 'fractul-medium';
    font-size: 1.5em;
    /* padding-bottom: 0.2rem; */
  }
  .security-content-text-title {
    font-family: 'fractul-medium';
    font-size: 1.2em;
  }
  .security-content-icon {
    width: 300px;
    /* outline: 1px solid black; */
    padding-left: 6rem;
  }
}
.option-container {
  display: flex;
  flex-direction: column;
  height: 600px;
  min-width: 300px;
  max-width: 300px;
  margin: 0rem 1rem;
  /* border-radius: 20px; */
  outline: 1px solid rgb(0, 0, 0, 0.05);
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 16px 10px;
  justify-content: space-between;
  align-items: center;
  font-family: "fractul-regular";
}
.option-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 5px;
}
.option-title {
  font-family: "fractul-bold";
  font-size: 2rem;
  margin-bottom: 0rem;
}
.option-description {
  font-size: 1.2rem;
}
.option-feature {
  padding: 13px 0px;
  display: flex;
  align-items: center;
}
.option-feature-icon {
  background-color: #00B609;
}
.option-footing {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.option-features {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 90%;
  height: 250px;
  margin-bottom: 2rem;
}
.option-features > div {
  margin: 6px 0px;
}
@media screen and (max-width: 1650px) {
  .option-container {
    display: flex;
    flex-direction: column;
    height: 600px;
    max-width: 450px;
    /* border-radius: 20px; */
    outline: 1px solid rgb(0, 0, 0);
    padding: 16px 10px;
    justify-content: space-between;
    align-items: center;
    font-family: "fractul-regular";
  }
}

@media screen and (max-width: 1500px) {
  .option-container {
    display: flex;
    flex-direction: column;
    height: 600px;
    min-width: 30%;
    max-width: 30%;
    /* border-radius: 20px; */
    outline: 1px solid rgb(0, 0, 0);
    padding: 16px 10px;
    justify-content: space-between;
    align-items: center;
    font-family: "fractul-regular";
  }
  .option-title {
    font-family: "fractul-bold";
    font-size: 1.8rem;
    margin-bottom: 0rem;
  }
  .option-description {
    font-size: 1.1rem;
  }
  .option-feature {
    padding: 13px 0px;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 992px) {
  .option-container {
    display: flex;
    flex-direction: column;
    height: 500px;
    min-width: 450px;
    max-width: 450px;
    /* border-radius: 20px; */
    outline: 1px solid rgb(0, 0, 0);
    padding: 16px 10px;
    justify-content: space-between;
    align-items: center;
    font-family: "fractul-regular";
    margin-bottom: 2rem;
  }
  .option-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .option-title {
    font-family: "fractul-bold";
    font-size: 2rem;
    margin-bottom: 0rem;
  }
  .option-description {
    font-size: 1.2rem;
  }
  .option-feature {
    padding: 13px 0px;
    display: flex;
    align-items: center;
  }
  .option-feature-icon {
    background-color: #00B609;
  }
}

@media screen and (max-width: 570px) {
  .option-container {
    display: flex;
    flex-direction: column;
    height: 500px;
    min-width: 350px;
    max-width: 350px;
    /* border-radius: 20px; */
    outline: 1px solid rgb(0, 0, 0);
    padding: 16px 10px;
    justify-content: space-between;
    align-items: center;
    font-family: "fractul-regular";
    margin-bottom: 2rem;
  }
  .option-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .option-title {
    font-family: "fractul-bold";
    font-size: 2rem;
    margin-bottom: 0rem;
  }
  .option-description {
    font-size: 1.2rem;
  }
  .option-feature {
    padding: 13px 0px;
    display: flex;
    align-items: center;
  }
  .option-feature-icon {
    background-color: #00B609;
  }
}

@media screen and (max-width: 410px) {
  .option-container {
    display: flex;
    flex-direction: column;
    height: 500px;
    min-width: 300px;
    max-width: 300px;
    /* border-radius: 20px; */
    outline: 1px solid rgb(0, 0, 0);
    padding: 16px 10px;
    justify-content: space-between;
    align-items: center;
    font-family: "fractul-regular";
    margin-bottom: 2rem;
  }
}
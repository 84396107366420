.humanKey {
  width: 53%;
  bottom: -7%;
  left: -42%;
}

.cactus {
  width: 20%;
  bottom: -7%;
  right: -15%;
}

.lock {
  width: 30%;
  top: 4%;
  right: -18%;
}

.page-heading-title.forgot {
  margin: auto;
  text-align: center;
}

.page-heading.forgot {
  min-height: 60vh !important;
}

.page-container.forgot {
  background: url("../../../assets/svg/forgot_back.svg") center center no-repeat;
}

@media screen and (max-height: 700px) {
  .lock {
    top: -10%;
  }
  .humanKey {
    width: 48%;
    bottom: -12%;
  }
}

@media screen and (max-width: 991px) {
  .humanKey {
    display: none;
  }
  .cactus {
    display: none;
  }
  .lock {
    display: none;
  }
}

@media screen and (max-height: 590px) {
  .page-heading-title.forgot {
    margin-bottom: 3rem;
  }
  .humanKey {
    width: 42%;
    bottom: -10%;
  }
  .cactus {
    width: 15%;
    bottom: -10%;
  }
  .lock {
    width: 25%;
    top: -25%;
  }
}

.feature-2 {
  min-height: 100%;
  height: 13rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(222, 222, 222, 0.2);
  backdrop-filter: blur(30px);
  position: relative;
  z-index: 10;
  flex: 1 0 30%;
  margin: -3px;
  border-radius: 20px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.feature-2 p {
  font-size: 125%;
  width: 74%;
}

.feature-title-2 {
  font-family: "fractul-bold";
  font-size: 1.45rem;
  margin-bottom: 0.3rem;
}

.feature-body-2 {
  font-family: "fractul-light";
  font-size: 100%;
  line-height: 22px;
}

.feature-icon-2 {
  margin-bottom: 0.6rem;
  font-size: 40px;
}

.feature-icon-bolt {
  margin-bottom: 0.6rem;
  font-size: 30px;
}

@media screen and (max-width: 1800px) {
  .feature-2 {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(222, 222, 222, 0.2);
    backdrop-filter: blur(30px);
    position: relative;
    z-index: 10;
    flex: 1 0 30%;
    margin: -3px;
    border-radius: 20px;
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  .feature-2 p {
    font-size: 125%;
    width: 74%;
  }

  .feature-title-2 {
    font-family: "fractul-bold";
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }

  .feature-body-2 {
    font-family: "fractul-light";
    font-size: 80%;
    line-height: 22px;
  }

  .feature-icon-2 {
    margin-bottom: 0.6rem;
    font-size: 40px;
  }

  .feature-icon-bolt {
    margin-bottom: 0.6rem;
    font-size: 30px;
  }
}

@media screen and (max-width: 992px) {
  .feature-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 100%;
    font-size: 70%;
  }
  .feature-title-2 {
  }

  .feature-body-2 {
    max-width: 100%;
    text-align: justify;
  }
}

.nav-container {
  max-width: 87% !important;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.main-navbar {
  min-height: 10vh;
  font-family: 'fractul-regular';
}

.bold {
  font-weight: 700;
}

.nav-link {
  text-align: center;
  font-size: 1rem;
  margin: 0 28px;
}

.nav-logo {
  text-decoration: none;
  width: 155px !important;
  color: #333;
}

.navbar-toggler-icon {
  font-size: 0.8em;
}

.active {
  font-weight: 700;
}

.navbar-toggler:hover {
  background-color: rgba(168, 168, 168, 0.205);
  border-radius: 0px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-light {
  border: none !important;
}

.navbar-light :focus {
  box-shadow: none !important;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler :focus {
  box-shadow: none !important;
}

.mobile-menu {
  color: #313133;
}

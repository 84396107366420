.download-button {
  background-color: #00b609;
  color: #fff;
  min-width: 12rem;
  border: none;
  text-align: center;
  margin: auto;
  font-family: 'fractul-regular';
  font-size: 1.1rem;
  padding: 11px 0;
  margin-top: 1rem;
  transition: all 0.5s;
}
.download-button{
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.download-button:after {
  content: "\27F6";
  position: absolute;
  opacity: 0;  
  top: 10px;
  right: -10px;
  transition: 0.5s;
}

.download-button:hover{
  padding-right: 24px;
  padding-left:8px;
}

.download-button:hover:after {
  opacity: 1;
  right: 10px;
}

/*
 */

/* .download-button {
    background-color: #00b609;
    color: #fff;
    min-width: 12rem;
    border: none;
    text-align: center;
    margin: auto;
    font-family: 'fractul-regular';
    font-size: 1.1rem;
    padding: 11px 0;
    margin-top: 1rem;
  } */

@media screen and (max-width: 992px) {
  .download-button {
    background-color: #00b609;
    color: #fff;
    font-size: 1rem;
    min-width: 12rem;
    border: none;
    text-align: center;
    margin: auto;
    padding: 12px 10px;
    font-family: 'fractul-regular';
  }
}
